module.exports = {
  //-- SITE SETTINGS -----
  author: "Sam Huang | sailplaneTW | 黃奕翔",
  siteTitle: "Sam Huang | 黃奕翔 | 忻旅科技 RevtelTech",
  siteShortTitle: "Sam Huang | 黃奕翔", // Used as logo text in header, footer, and splash screen
  siteDescription:
    "佔得人間一味愚，此心安處是吾鄉",
  siteUrl: "https://www.sam-huang.info",
  siteLanguage: "zh_TW",
  siteIcon: "content/logo5.png", // Relative to gatsby-config file
  seoTitleSuffix: "-", // SEO title syntax will be e.g. "Imprint - {seoTitleSuffix}"
  useCookieBar: false, // If you use Google Analytics and want to be GDPR-compliant, set it to true
  googleAnalyticsTrackingId: "UA-210284070-1", // e.g. UA-XXXXXX-X
  clarityId: "8wotw8nwzk",

  // -- THEME SETTINGS -----
  colors: {
    lightTheme: {
      primary: "#000000",
      secondary: "#FFF4D9",
      tertiary: "#F2F2F2",
      text: "#000000",
      subtext: "#555555",
      background: "#FFFFFF",
      card: "#FFFFFF",
      scrollBar: "rgba(0, 0, 0, 0.5)",
      boxShadow: "rgba(0, 0, 0, 0.16)",
      boxShadowHover: "rgba(0, 0, 0, 0.32)",
    },
    darkTheme: {
      primary: "#FAFAFA",
      secondary: "#2A2926",
      tertiary: "#252525",
      text: "rgba(255, 255, 255, 0.87)",
      subtext: "#AAAAAA",
      background: "#121212",
      card: "#1C1C1C",
      scrollBar: "rgba(255, 255, 255, 0.5)",
      boxShadow: "rgba(0, 0, 0, 0.16)",
      boxShadowHover: "rgba(0, 0, 0, 0.32)",
    },
  },
  fonts: {
    primary: "Roboto, Arial, sans-serif",
  },

  //-- ARTICLES SECTION SETTINGS -----
  // You can create your own Medium feed with this rss to json converter: https://rss2json.com/
  // To access your Medium RSS feed, just replace this url with your username: https://medium.com/feed/@{yourname}
  mediumRssFeed:
    "https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fmedium.com%2Ffeed%2F%40sam-huang",
  // rssFeed: "https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fwww.theguardian.com%2Finternational%2Frss",

  shownArticles: 5,

  //-- SOCIAL MEDIA SETTINGS -----
  // There are icons available for the following platforms:
  // Medium, GitHub, LinkedIn, XING, Behance, E-Mail
  socialMedia: [
    
    {
      name: "Mail",
      url: "mailto:sailplaneTW@gmail.com",
    },
      {
          name:"Line",
          url: "https://l.revtel.app/j/line",
      },
    {
      name: "Facebook",
      url: "https://l.revtel.app/j/facebook",
    },

    {
      name: "Medium",
        url: "https://l.revtel.app/j/medium",
    },
    {
        name: "Slide",
        url:"https://l.revtel.app/j/slides"
    },
    {
      name: "Github",
      url:"https://l.revtel.app/j/github"
    },
    {
      name: "Linkedin",
        url:"https://l.revtel.app/j/linkedin"
    },
  ],

  //-- NAVIGATION SETTINGS -----
  navLinks: {
    menu: [
      {
        name: "About",
        url: "/#about",
      },
      {
        name: "History",
        url: "/#projects",
      },
      {
        name: "Contact",
        url: "/#contact",
      },
    ],
  },
  footerLinks: [
    /*
    {
      name: "Privacy",
      url: "/privacy",
    },
    {
      name: "Imprint",
      url: "/imprint",
    },
    */
  ],
}
